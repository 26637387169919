import { type PropsWithChildren, useEffect } from "react";

import { Card } from "flowbite-react";
import { useRouter } from "next/router";

import { MaybeImpersonationBanner } from "@components/Impersonation";
import { Invitation } from "@components/Invitation";
import { LogoWithCoBranding } from "@components/LogoWithCoBranding";
import { UnauthedFooter } from "@components/UnauthedFooter";
import { RetainQueryLink } from "@components/_shared/RetainQueryLink";
import { PREFIX_JOIN } from "@helpers/routes";
import { BASE_URL, Routes } from "@helpers/routesSimple";
import { useLayoutData } from "@layouts/LayoutDataProvider";
import { StyledText } from "@riffs/StyledText";
import { useForteDirectInvitation } from "@util/useForteDirectInvitation";

export const FrontDoorLayout = ({
  children,
  heading,
  lowerLine,
  showInvitation = false,
}: PropsWithChildren & {
  heading?: string;
  lowerLine?: string;
  showInvitation?: boolean;
}) => {
  const router = useRouter();

  const { setInfoBlockHeading, setInfoBlockLowerLine } = useLayoutData();

  // InfoBlock-related
  useEffect(() => {
    setInfoBlockHeading(heading);
    setInfoBlockLowerLine(lowerLine);
  }, [
    heading,
    lowerLine,
    setInfoBlockHeading,
    setInfoBlockLowerLine,
    router.pathname, // ! Make sure this calls on every route change, otherwise the previous page's heading/lowerLine will persist!
  ]);

  const { invitationToken } = useForteDirectInvitation();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen pt-8 pb-4 px-4">
      <MaybeImpersonationBanner />
      <div className="flex-grow flex flex-col justify-center w-full max-w-md">
        <LogoWithCoBranding href={BASE_URL} />
        <Card className="w-full max-w-md mt-8">
          <div>
            {showInvitation && invitationToken && (
              <Invitation invitationToken={invitationToken} />
            )}

            {children}

            {(router?.asPath?.includes(PREFIX_JOIN) ||
              router?.asPath?.includes(Routes.SIGN_IN)) && (
              <div className="flex-0 flex flex-col justify-center items-center mt-4">
                {router?.asPath?.includes(PREFIX_JOIN) && (
                  <StyledText>
                    Already have an account?{" "}
                    <RetainQueryLink
                      href={Routes.SIGN_IN}
                      className="underline hover:text-gray-200"
                    >
                      Sign In
                    </RetainQueryLink>
                  </StyledText>
                )}

                {router?.asPath?.includes(Routes.SIGN_IN) && (
                  <StyledText size="sm">
                    Don't have an account?{" "}
                    <RetainQueryLink
                      href={PREFIX_JOIN}
                      className="underline hover:text-gray-200"
                    >
                      Join Forte
                    </RetainQueryLink>
                  </StyledText>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>

      <UnauthedFooter />
    </div>
  );
};
