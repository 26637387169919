// eslint-disable-next-line unicorn/prefer-node-protocol
import { type BinaryLike, type KeyObject, createHmac } from "crypto";
import * as CryptoJS from "crypto-js";

import { DOMAIN } from "../helpers/routes";

export function encryptUsingAES256(data: Record<string, unknown>) {
  // eslint-disable-next-line n/no-process-env
  if (!process.env.NEXT_PUBLIC_ENCRYPTION_KEY) {
    throw new Error("Missing NEXT_PUBLIC_ENCRYPTION_KEY");
  }

  // eslint-disable-next-line n/no-process-env
  if (!process.env.NEXT_PUBLIC_ENCRYPTION_IV) {
    throw new Error("Missing NEXT_PUBLIC_ENCRYPTION_IV");
  }

  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
    // eslint-disable-next-line n/no-process-env
    CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_ENCRYPTION_KEY),
    {
      keySize: 128 / 8,
      // eslint-disable-next-line n/no-process-env
      iv: CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_ENCRYPTION_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return encrypted;
}

export function getSecretHash(
  cognitoClientId: string | undefined,
  cognitoSecretKey: BinaryLike | KeyObject | undefined,
  username: string,
) {
  if (!cognitoSecretKey) {
    return "";
  }

  const hasher = createHmac("sha256", cognitoSecretKey);
  // AWS wants `"Username" + "Client Id"`
  hasher.update(`${username}${cognitoClientId}`);
  const secretHash = hasher.digest("base64");
  return secretHash;
}

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expiration?: number,
) => {
  const date = new Date();
  const days = 30;
  date.setTime(
    date.getTime() + (expiration ? expiration : days * 24 * 60 * 60 * 1_000),
  );
  const expires = `expires=${date.toISOString()}`;
  const domain = `domain=${
    window.location.hostname.includes("localhost") ? "localhost" : DOMAIN
  }`;
  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = `${cookieName}=${cookieValue};${expires};${domain};path=/`;
};

export function isNotError<T>(argument: T | Error): argument is T {
  return !(argument instanceof Error);
}

export function isNotNull<T>(argument: T | null): argument is T {
  return argument !== null;
}

/**
 * Adds a possession apostrophe and the "s" as appropriate
 * i.e. either 's or s'
 */
export function possessionApostrophize(name: string): string {
  if (name.endsWith("s") || name.endsWith("z")) {
    return `${name}'`;
  }

  return `${name}'s`;
}

export type StudioRoomT =
  | {
      learnerId: string;
      studioRoomId: string;
      teacherId: string;
      type: "adhoc";
    }
  | {
      studioRoomId: string;
      type: "tr";
    };

export function parseStudioRoom(rawStudioRoomId: string): StudioRoomT {
  const studioRoom: StudioRoomT = rawStudioRoomId.startsWith("adhoc:")
    ? {
        type: "adhoc",
        studioRoomId: rawStudioRoomId,
        teacherId: rawStudioRoomId.split(":")[1],
        learnerId: rawStudioRoomId.split(":")[2],
      }
    : { type: "tr", studioRoomId: rawStudioRoomId };
  return studioRoom;
}

export function getAudioLevelPercentage(level: number) {
  return (level * 100) / 200; // 0 to 100
}

export const round = (num: number, decimals = 2) =>
  Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;

export function getStandardDeviation(values: number[]): number {
  if (values.length <= 0) {
    return 0;
  }

  const valueAverage: number =
    values.reduce(
      (partialSum: number, value: number) => partialSum + value,
      0,
    ) / values.length;

  const diffSquared: number[] = values.map(
    (value: number) => value - valueAverage ** 2,
  );

  const stdDev: number = Math.sqrt(
    diffSquared.reduce(
      (partialSum: number, value: number) => partialSum + value,
      0,
    ) / diffSquared.length,
  );

  return round(stdDev);
}

export const formatCentsAsCurrency = (amountCents: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amountCents / 100);

export function parseQueryParamNumber(
  value: undefined | string | Array<string>,
): null | number {
  if (typeof value !== "string") {
    return null;
  }

  return parseInt(value, 10);
}

export function parseQueryParamString(
  value: undefined | string | Array<string>,
): null | string {
  if (typeof value !== "string") {
    return null;
  }

  return value;
}

// useNextQuery().queries has a richer type
export function parseQueryParamStringArray(
  value:
    | undefined
    | null
    | string
    | number
    | boolean
    | ReadonlyArray<string>
    | ReadonlyArray<number>
    | ReadonlyArray<boolean>,
): Array<string>;
// useRouter().query
export function parseQueryParamStringArray(
  value: undefined | string | ReadonlyArray<string>,
): Array<string>;
export function parseQueryParamStringArray(
  value:
    | undefined
    | null
    | string
    | number
    | boolean
    | ReadonlyArray<string>
    | ReadonlyArray<number>
    | ReadonlyArray<boolean>,
): Array<string> {
  if (
    typeof value === "string" ||
    typeof value === "boolean" ||
    typeof value === "number"
  ) {
    return value
      .toString()
      .split(",")
      .filter((val) => val !== "");
  }

  if (Array.isArray(value)) {
    return value.map((entry) => entry.toString());
  }

  return [];
}
