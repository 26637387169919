import { useRouter } from "next/router";

import { SignupModeOptions, type SignupModeT } from "@helpers/joinHelpers";

type InvitationFlags = {
  availableJoinModes: SignupModeT[];
  canAcceptOnBehalf: boolean;
  canJoinAsMinorStudent: boolean;
  needsOrganizationId: boolean;
  orgOrTrInvitation?: "OrgInvitation" | "TeachingRelationshipInvitation";
  shouldAutomaticallyAcceptInvitationOnSignUp: boolean;
  shouldIncludeInvitationTokenOnSignUp: boolean;
};

const INVITATION_QUERY_PARAM = "invitationToken";

// Token prefixes
const PREFIX_TR_STUDENT_INVITED = "t:si:";

export const useForteDirectInvitation = () => {
  const router = useRouter();

  const invitationToken = Array.isArray(router.query[INVITATION_QUERY_PARAM])
    ? router.query[INVITATION_QUERY_PARAM][0]
    : router.query[INVITATION_QUERY_PARAM];

  const clearInvitationToken = (options?: { callbackUrl: string }) => {
    router.replace(
      { pathname: router.pathname, query: router.query },
      undefined,
      { shallow: true },
    );
    if (options?.callbackUrl) {
      router.push(options.callbackUrl);
    }
  };

  const invitationFlags: InvitationFlags = {
    availableJoinModes: [] as SignupModeT[],
    needsOrganizationId: false,
    canAcceptOnBehalf: false,
    canJoinAsMinorStudent: true,
    shouldIncludeInvitationTokenOnSignUp: false,
    orgOrTrInvitation: undefined as
      | "OrgInvitation"
      | "TeachingRelationshipInvitation"
      | undefined,
    shouldAutomaticallyAcceptInvitationOnSignUp: false,
  };

  const prefixActions = {
    [PREFIX_TR_STUDENT_INVITED]: () => {
      Object.assign(invitationFlags, {
        orgOrTrInvitation: "TeachingRelationshipInvitation",
        availableJoinModes: [
          SignupModeOptions.PARENT,
          SignupModeOptions.LEARNER,
        ],
        needsOrganizationId: true,
        canAcceptOnBehalf: true,
        canJoinAsMinorStudent: false,
        shouldAutomaticallyAcceptInvitationOnSignUp: true,
      });
    },
  };

  for (const [prefix, action] of Object.entries(prefixActions)) {
    if (invitationToken?.includes(prefix)) {
      action();
    }
  }

  return {
    invitationToken,
    invitationFlags,
    clearInvitationToken,
    addInvitationTokenToUrl: (url: string) => {
      if (!invitationToken) {
        return url;
      }

      const existingParams = new URLSearchParams(url);
      existingParams.set(INVITATION_QUERY_PARAM, invitationToken);

      // If there are existing query parameters, append them to the URL
      const updatedUrl =
        existingParams.toString() === ""
          ? url
          : `${url.split("?")[0]}?${existingParams.toString()}`;

      return updatedUrl;
    },
  };
};
