import { type PropsWithChildren } from "react";

import { type Session } from "next-auth";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { encode } from "querystring";

import { Routes } from "@helpers/routesSimple";
import usePreviewBranchRedirect from "@hooks/usePreviewBranchRedirect";
import { directlySetWindowLocation } from "@util/directlySetWindowLocation";

import {
  INDEX,
  isPathInArray,
  mayBeUnauthenticatedPaths,
  mustBeUnauthenticatedPaths,
} from "../helpers/routes";

/**
 * The values must match the strings used in node_modules/next-auth/react/index.d.ts.
 */
export enum SessionStatus {
  AUTHENTICATED = "authenticated",
  LOADING = "loading",
  UNAUTHENTICATED = "unauthenticated",
}

type SessionStatusValue = SessionStatus[keyof SessionStatus];

/**
 * Pass router.pathname as `path` because it strips query parameters.
 */
const getAllowedPath = (
  session: Session | null,
  status: SessionStatusValue,
  path: string,
) => {
  if (session && "passwordResetSession" in session) {
    return path === Routes.SIGN_OUT ? path : Routes.MUST_RESET_PASSWORD;
  }

  if (status === SessionStatus.UNAUTHENTICATED) {
    const isAllowed = isPathInArray(path, [
      ...mayBeUnauthenticatedPaths,
      ...mustBeUnauthenticatedPaths,
    ]);
    if (isAllowed) {
      return path;
    }
  }

  if (status === SessionStatus.AUTHENTICATED) {
    const isAttemptingToGoToUnauthenticatedOnlyRoute =
      mustBeUnauthenticatedPaths.some((allowedRoute) =>
        path.startsWith(allowedRoute),
      );
    // If the user is trying to go to an unauthenticated-only route and they're logged in, then bounce them to safety.
    if (isAttemptingToGoToUnauthenticatedOnlyRoute) {
      // eslint-disable-next-line no-console
      console.debug(
        "User is Authenticated but isAttemptingToGoToUnauthenticatedOnlyRoute",
      );
      return INDEX;
    }

    return path;
  }

  return Routes.SIGN_IN;
};

export const AuthGatekeeper = ({ children }: PropsWithChildren) => {
  const {
    data: session,
    status,
  }: { data: Session | null; status: SessionStatusValue } = useSession();
  const router = useRouter();
  usePreviewBranchRedirect();

  if (status === "loading") {
    return null;
  }

  const allowedPath = getAllowedPath(session, status, router.pathname);

  if (allowedPath !== router.pathname) {
    const searchParams = new URLSearchParams(encode(router.query));

    const queryParamsStr = searchParams.toString();

    const pathWithQueryParams = queryParamsStr
      ? `${allowedPath}?${queryParamsStr}`
      : allowedPath;

    directlySetWindowLocation(pathWithQueryParams);
    return null;
  }

  return <>{children}</>;
};
