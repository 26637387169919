/**
 * Sets `window.location.href` to force an immediate redirect.
 */
export function directlySetWindowLocation(path: string) {
  console.debug("directlySetWindowLocation: ", path);

  // Check that window is defined to ensure this executes only clientside.
  if (typeof window !== "undefined") {
    window.location.href = path;
  }
}
