// Check logic in components/MaybeViewerProvider.tsx and components/AuthGatekeeper.tsx and _app.tsx to see rules for authorization and redirects.
import { MARKETING_SITE } from "../constants/config";
import {
  type RoleT,
  RoleTypeEnum,
  type StudentOrParentRoleT,
} from "../constants/role";
import { typecheckExhaustiveNeverCase } from "../util/helper";
import { SignupModeOptions } from "./joinHelpers";
import {
  ANONYMOUS_BROWSE,
  Routes,
  TEACHER_PROFILE_BY_TEACHEROF_ID,
} from "./routesSimple";
import { replaceTrailingSquareBracketsChunk } from "./stringHelper";

export { BASE_URL, DOMAIN, LOCALHOST } from "./routesSimple";

export const ABOUT = `${MARKETING_SITE}/about/`;
export const BLOG = `${MARKETING_SITE}/blog/`;
export const CONTACT_US = `${MARKETING_SITE}/contact-us/`;
export const HELP = `${MARKETING_SITE}/help/`;
export const INVITE_TEACHER = `${MARKETING_SITE}/refer`; // ! No trailing slash!
export const PRIVACY_POLICY = `${MARKETING_SITE}/privacy-policy/`;
export const TERMS_OF_USE = `${MARKETING_SITE}/terms-of-use/`;

/**
 * General (for any role)
 */
export const INDEX = "/"; // aka ROOT or SLASH
export const ACCEPT_INVITATION = "/accept-invitation";

export const PREFIX_JOIN = "/join";
export const JOIN_AS_PARENT = PREFIX_JOIN + "/" + SignupModeOptions.PARENT;
export const JOIN_AS_LEARNER = PREFIX_JOIN + "/" + SignupModeOptions.LEARNER;
export const JOIN_AS_TEACHER = PREFIX_JOIN + "/" + SignupModeOptions.TEACHER;

export const PREFIX_STUDIO = `/studio`;
export const STUDIO_ILE = `/studio/`;
// @deprecated Use Routes enum instead
export const SETTINGS = "/settings";
// @deprecated Use Routes enum instead
export const SIGN_IN = "/sign-in";
// @deprecated Use Routes enum instead
export const SIGN_OUT = "/sign-out";

export const SOCIAL_FACEBOOK = "https://www.facebook.com/forte_lessons";
export const SOCIAL_INSTAGRAM = "https://www.instagram.com/fortelessons/";
export const SOCIAL_TWITTER = "https://twitter.com/fortelessons";
export const SOCIAL_YOUTUBE =
  "https://www.youtube.com/channel/UCgwf2bYUEqPCzSp7jZMjyrQ";

export const PREFIX_TEACHER = "/teacher";
// @deprecated Use Routes enum instead
export const TEACHER_STUDENTS = `${PREFIX_TEACHER}/students`;
// @deprecated Use Routes enum instead
export const TEACHER_PROFILE = `${PREFIX_TEACHER}/profile`;
export const TEACHER_STANDALONE_DASHBOARD = "/standalone/dashboard";

export const PREFIX_STUDENT = "/student";

const STUDENT_RESERVE_BASE = `/reserve`;
export const STUDENT_RESERVE_SCHEDULE = `${STUDENT_RESERVE_BASE}/schedule`;
export const STUDENT_RESERVE_REGISTER = `${STUDENT_RESERVE_BASE}/register`;
export const STUDENT_RESERVE_SUMMARY = `${STUDENT_RESERVE_BASE}/summary`;
export const STUDENT_RESERVE_CHECKOUT = `${STUDENT_RESERVE_BASE}/checkout`;
export const STUDENT_RESERVE_BOOKED = `${STUDENT_RESERVE_BASE}/booked`;

export function getTeacherSchedulePath(
  teacherOfId: string,
  reservePath:
    | typeof STUDENT_RESERVE_SCHEDULE
    | typeof STUDENT_RESERVE_REGISTER
    | typeof STUDENT_RESERVE_SUMMARY
    | typeof STUDENT_RESERVE_CHECKOUT
    | typeof STUDENT_RESERVE_BOOKED,
) {
  return `${Routes.TEACHER_PROFILE}/${teacherOfId}${reservePath}`;
}

const PREFIX_ADMIN = "/admin";

export enum SurveyQuestionEnum {
  ageGroup = "ageGroup",
  aspirationLevel = "aspirationLevel",
  genres = "genres",
  musicTheoryLevel = "musicTheoryLevel",
  skillLevel = "skillLevel",
}

export enum SurveyLeadQualifierEnum {
  budget = "budget",
  experience = "experience",
  frequency = "frequency",
}

export enum SurveySnippetEnum {
  // Instrument survey snippets
  // SNIPPET_1 = "snippet-one", not in use
  SNIPPET_2 = "snippet-two",
  SNIPPET_3 = "snippet-three",
  // SNIPPET_4 = "snippet-four", not in use

  // Lead qualifier snippets
  // SNIPPET_5 = "snippet-five", not in use
  SNIPPET_6 = "snippet-six",
  SNIPPET_7 = "snippet-seven",
}

export const SURVEY = "/explore/";
export const SURVEY_INSTRUMENT = `${SURVEY}[instrumentId]`;
export const SURVEY_AGE_GROUP = `${SURVEY_INSTRUMENT}/${SurveyQuestionEnum.ageGroup}`;
export const SURVEY_LEARNER_NAME = `${SURVEY_INSTRUMENT}/learnerName`;
export const SURVEY_PARENT_EMAIL = `${SURVEY_INSTRUMENT}/parent-email`;
export const SURVEY_ASPIRATION_LEVEL = `${SURVEY_INSTRUMENT}/${SurveyQuestionEnum.aspirationLevel}`;
export const SURVEY_SKILL_LEVEL = `${SURVEY_INSTRUMENT}/${SurveyQuestionEnum.skillLevel}`;
export const SURVEY_MUSIC_THEORY_LEVEL = `${SURVEY_INSTRUMENT}/${SurveyQuestionEnum.musicTheoryLevel}`;
export const SURVEY_GENRES = `${SURVEY_INSTRUMENT}/${SurveyQuestionEnum.genres}`;
export const SURVEY_SIGNUP_OR_LOGIN = `${SURVEY_INSTRUMENT}/signUpOrLogin`;

// Lead qualifier routes
export const SURVEY_LEAD_QUALIFIER_EXPERIENCE = `${SURVEY_INSTRUMENT}/${SurveyLeadQualifierEnum.experience}`;
export const SURVEY_LEAD_QUALIFIER_BUDGET = `${SURVEY_INSTRUMENT}/${SurveyLeadQualifierEnum.budget}`;
export const SURVEY_LEAD_QUALIFIER_FREQUENCY = `${SURVEY_INSTRUMENT}/${SurveyLeadQualifierEnum.frequency}`;

// Snippet routes
// export const SURVEY_SNIPPET_1 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_1}`;
export const SURVEY_SNIPPET_2 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_2}`;
export const SURVEY_SNIPPET_3 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_3}`;
// export const SURVEY_SNIPPET_4 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_4}`;
// export const SURVEY_SNIPPET_5 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_5}`;
export const SURVEY_SNIPPET_6 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_6}`;
export const SURVEY_SNIPPET_7 = `${SURVEY_INSTRUMENT}/${SurveySnippetEnum.SNIPPET_7}`;

// Populates a `SURVEY_INSTRUMENT` URL
export const getExploreRouteForInstrumentId = (instrumentId: string) => {
  return `${SURVEY}${instrumentId}`;
};

export const generateTeacherResultsPathForLearnerOfId = (learnerOfId: string) =>
  PREFIX_STUDENT + `/teachers/${learnerOfId}`;

/**
 * Routes that the user is allowed to visit even when unauthenticated.
 * SIGN_IN is a weird exception -- when we do the login, we call to NextAuth which responds with
 * a set-cookie, which immediately makes us status:authenticated, but we're still on the /sign-in route.
 * Therefore SIGN_IN must be in the `optionalAuth` bucket, and it's in charge of redirecting itself
 * on its initial render. This same weirdness also applies to `JOIN`.
 */
export const mayBeUnauthenticatedPaths = [
  ACCEPT_INVITATION,
  Routes.MUST_RESET_PASSWORD, // A user visiting here wouldn’t have a `viewer` although technically *would* be authenticated.
  Routes.SIGN_OUT,
  SURVEY,
  STUDIO_ILE,
  TEACHER_PROFILE_BY_TEACHEROF_ID,
  STUDENT_RESERVE_SCHEDULE,
  STUDENT_RESERVE_SUMMARY,
  Routes.NOT_FOUND,
  ANONYMOUS_BROWSE,
  Routes.SIGN_IN, // See comment above.
  Routes.DOWNLOAD_RECORDING,
  PREFIX_JOIN, // See comment above.
];

export const roleAgnosticPaths = [Routes.MUST_RESET_PASSWORD, Routes.SETTINGS];

// Routes that the user can only visit if NOT authenticated.
export const mustBeUnauthenticatedPaths = [
  Routes.FORGOT_PASSWORD,
  Routes.RESET_PASSWORD,
];

/**
 * Be sure that you use MaybeViewer instead of AuthedViewer for any functions within these pages.
 * Paths in which you may not have a viewer.
 * TODO: Matt and Ryan want to improve this name.
 */
export const viewerOptionalPaths = [
  ...mustBeUnauthenticatedPaths,
  ...mayBeUnauthenticatedPaths,
];

/**
 * @param {string} routerPathName From router.pathname, which strips query parameters.
 * @param {string[]} paths Can include dynamic paths whose slugs include placeholders (such as `/teacher/profile/[teacherOfId]`).
 * @returns
 */
export function isPathInArray(
  routerPathName: string,
  paths: string[],
): boolean {
  return paths.some((path) => {
    return routerPathName.startsWith(
      replaceTrailingSquareBracketsChunk(path, ""),
    );
  });
}

export const roleCanViewRoute = (currentRole: RoleT, path: string): boolean => {
  if (
    isPathInArray(path, [...mayBeUnauthenticatedPaths, ...roleAgnosticPaths])
  ) {
    return true;
  }

  const allowedRootPathFragmentLookup = {
    [RoleTypeEnum.TEACHER]: [
      PREFIX_TEACHER,
      PREFIX_STUDIO,
      /**
       * Because of similar weirdness as explained above mayBeUnauthenticatedPaths.
       */
      PREFIX_JOIN,
    ],
    [RoleTypeEnum.STUDENT_OR_PARENT]: [
      PREFIX_STUDENT,
      PREFIX_STUDIO,
      Routes.SET_PASSWORD,
      TEACHER_PROFILE_BY_TEACHEROF_ID,
      PREFIX_JOIN, // Because of similar weirdness as explained above mayBeUnauthenticatedPaths.
    ],
    [RoleTypeEnum.FORTE_ADMIN]: [
      PREFIX_ADMIN,
      PREFIX_STUDIO,
      TEACHER_PROFILE_BY_TEACHEROF_ID,
      PREFIX_JOIN, // Because of similar weirdness as explained above mayBeUnauthenticatedPaths.
    ],
  };

  const allowedAuthedRoutePrefixes =
    allowedRootPathFragmentLookup[currentRole.type];
  const result = isPathInArray(path, allowedAuthedRoutePrefixes);

  return result;
};

/**
 * This can be thought of as the default / "home" for the role.
 */
export function getRedirectPathForRole(
  role:
    | Exclude<RoleT, StudentOrParentRoleT>
    | Pick<
        StudentOrParentRoleT,
        "type" | "viewerNumTeachingRelationshipsAsLearner"
      >,
): string {
  let redirectToPath: string;

  if (role.type === RoleTypeEnum.STUDENT_OR_PARENT) {
    redirectToPath = Routes.STUDENT_LEARNING_HUB;
  } else if (role.type === RoleTypeEnum.TEACHER) {
    redirectToPath = Routes.TEACHER_STUDENTS;
  } else if (role.type === RoleTypeEnum.FORTE_ADMIN) {
    redirectToPath = Routes.ADMIN_USERS;
  } else {
    typecheckExhaustiveNeverCase(role);
    throw new Error("Invalid role.type");
  }

  // eslint-disable-next-line no-console
  console.debug(`Redirecting to ${redirectToPath}. Role: ${role.type}`);

  return redirectToPath;
}
