import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { ACCEPT_INVITATION, HELP, SIGN_IN, SIGN_OUT } from "@helpers/routes";
import { StyledText } from "@riffs/StyledText";
import { useForteDirectInvitation } from "@util/useForteDirectInvitation";

import { SessionStatus } from "./AuthGatekeeper";
import { ExternalLink } from "./_shared/ExternalLink";

const SignOutAndPreserveInvitationToken = () => {
  const { invitationToken } = useForteDirectInvitation();

  return (
    <Link
      href={{
        pathname: SIGN_OUT,
        query: {
          callbackUrl: SIGN_IN,
          invitationToken,
        },
      }}
      className="underline"
    >
      Sign Out
    </Link>
  );
};

export const UnauthedFooter = () => {
  const router = useRouter();
  const { status } = useSession();
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="flex flex-col gap-2 py-6 mt-6">
        {router?.asPath.includes(ACCEPT_INVITATION) &&
          status === SessionStatus.AUTHENTICATED && (
            <StyledText className="text-center">
              <SignOutAndPreserveInvitationToken />
            </StyledText>
          )}

        <StyledText size="sm" secondary className="text-center">
          {`Problems with your account? Go to the `}
          <ExternalLink href={HELP}>Help Center</ExternalLink>
        </StyledText>
        <StyledText size="xs" secondary className="text-center">
          All information entered into Forte is stored securely and will never
          be sold under any circumstance.
        </StyledText>
        <p className="text-forteCharcoal-60 dark:text-gray-600 text-xs text-center px-4 pt-10 pb-4 mt-auto">
          © {year} Forte. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};
