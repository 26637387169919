import { type PropsWithChildren } from "react";

import Link, { type LinkProps } from "next/link";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";

export const RetainQueryLink = ({
  href,
  className,
  ...props
}: LinkProps & PropsWithChildren & { className?: string }) => {
  const router = useRouter();

  const pathname = typeof href === "object" ? href.pathname : href;

  const query =
    typeof href === "object" && typeof href.query === "object"
      ? href.query
      : {};

  return (
    <Link
      {...props}
      className={twMerge("text-blue-400", className)}
      href={{
        pathname,
        query: {
          ...router.query,
          ...query,
        },
      }}
    />
  );
};
