import React from "react";

import { Media, Message } from "@twilio/conversations";
import orderBy from "lodash/orderBy";

import { Loading } from "@riffs/Loading";

import { FileBubble, TextBubble, UploadingBubble } from "./ChatBubble";

export const ChatMessages = ({
  loading,
  isILE,
  messageList,
  maybeRenderDate,
  downloadingFileIds,
  downloadHandler,
  uploadingMessageIndexes,
}: {
  isILE: boolean;
  loading: boolean;
  messageList: Message[];
  maybeRenderDate: (date: Date | null) => JSX.Element | null;
  downloadingFileIds: string[];
  downloadHandler: (media: Media) => Promise<void>;
  uploadingMessageIndexes: number[];
}) => {
  return (
    <>
      {messageList.length === 0 && loading && (
        <div className="flex flex-col flex-1 h-full">
          <div className="flex flex-col flex-1 items-center justify-center m-auto">
            <Loading />
          </div>
        </div>
      )}
      {messageList.length === 0 && !loading && (
        <div className="flex flex-col flex-1 h-full text-gray-400">
          <div className="h-full flex flex-col items-center justify-center">
            <div className="flex flex-col gap-2 items-center">
              <div className={"px-16 text-center"}>
                Start a conversation by typing your message below.
              </div>
            </div>
          </div>
        </div>
      )}
      {messageList.length > 0 && !loading && (
        <>
          {orderBy(messageList, "index", "asc").map(
            (messageObj: Message, index: number) => {
              return (
                <React.Fragment key={index}>
                  {maybeRenderDate(messageObj.dateCreated)}

                  {messageObj.body && (
                    <TextBubble messageObj={messageObj} isILE={isILE} />
                  )}

                  {messageObj?.type === "media" &&
                    messageObj.attachedMedia &&
                    messageObj.attachedMedia.map((media, mediaIndex) => {
                      return (
                        <FileBubble
                          downloadingFileIds={downloadingFileIds}
                          messageObj={messageObj}
                          downloadHandler={downloadHandler}
                          media={media}
                          key={mediaIndex}
                          isILE={isILE}
                        />
                      );
                    })}
                </React.Fragment>
              );
            },
          )}
          {uploadingMessageIndexes.map((_item, index) => {
            return <UploadingBubble key={index} isILE={isILE} />;
          })}
        </>
      )}
    </>
  );
};
