import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";

import first from "lodash/first";

import { useNextQuery } from "@util/useNextQuery";
import { parseQueryParamStringArray } from "@util/util";

import {
  type KnownFortePromoIds,
  KnownUtmQueryParams,
  OnboardingQueryParams,
  PersonalDetailsQueryParams,
} from "../constants/queryParams";

import { type OnboardingDetailsInput } from "@graphql";

const AllKnownQueryParams = [
  ...Object.values(KnownUtmQueryParams),
  ...Object.values(OnboardingQueryParams),
  ...Object.values(PersonalDetailsQueryParams),
] as Array<string>;

type OnboardingQueryParamCaptureT = {
  email: string | null;
  firstName: string | null;
  institutionFilter: string | null;
  onboardingMetadata: OnboardingDetailsInput;
  signupCode: string | null;
};

const OnboardingQueryParamCaptureContext =
  createContext<OnboardingQueryParamCaptureT | null>(null);

export const useOnboardingQueryParamCaptureContext = () => {
  const context = useContext(OnboardingQueryParamCaptureContext);
  if (context === null) {
    throw new Error(
      "useOnboardingQueryParamCaptureContext() must be wrapped in an <OnboardingDetailsProvider>",
    );
  }

  return context;
};

export const OnboardingQueryParamCaptureProvider = ({
  children,
}: PropsWithChildren) => {
  const { queries } = useNextQuery();

  const arbitraryUnhandledQueryParams = useMemo(() => {
    const arbitraryQueryParams: Record<
      string,
      | string
      | number
      | boolean
      | readonly string[]
      | readonly number[]
      | readonly boolean[]
      | null
      | undefined
    > = {};

    for (const [key, value] of Object.entries(queries)) {
      if (!AllKnownQueryParams.includes(key)) {
        arbitraryQueryParams[key] = value;
      }
    }

    return JSON.stringify(arbitraryQueryParams);
  }, [queries]);

  // 3/10/25: TopMusic sent out an email where they added their own `utm_source`, `utm_campaign`, `and `utm_medium`,
  // and we were naively parsing it as a string, but it would actually be an array since there were multiple values.
  // The array would be sent to graphql and causing an InputError during validation.
  // We'll use the first() value found to be a bit safer about it.

  const utmContent =
    first(
      parseQueryParamStringArray(queries[KnownUtmQueryParams.utm_content]),
    ) ?? null;

  const utmMedium =
    first(
      parseQueryParamStringArray(queries[KnownUtmQueryParams.utm_medium]),
    ) ?? null;

  const utmSource =
    first(
      parseQueryParamStringArray(queries[KnownUtmQueryParams.utm_source]),
    ) ?? null;

  const utmCampaign =
    first(
      parseQueryParamStringArray(queries[KnownUtmQueryParams.utm_campaign]),
    ) ?? null;

  const utmTerm =
    first(parseQueryParamStringArray(queries[KnownUtmQueryParams.utm_term])) ??
    null;

  const originMarketingPageSlug =
    OnboardingQueryParams.ORIGIN_SLUG in queries
      ? (queries[OnboardingQueryParams.ORIGIN_SLUG] as string)
      : null;

  const institutionFilter =
    OnboardingQueryParams.INSTITUTION_FILTER in queries
      ? (queries[OnboardingQueryParams.INSTITUTION_FILTER] as string)
      : null;

  const referringPartnerIdFromQueryParams =
    OnboardingQueryParams.REFERRING_PARTNER_ID in queries
      ? (queries[OnboardingQueryParams.REFERRING_PARTNER_ID] as string)
      : null;

  const userReferralCode =
    OnboardingQueryParams.USER_REFERRAL_CODE in queries
      ? (queries[OnboardingQueryParams.USER_REFERRAL_CODE] as string)
      : null;

  const fortePromoId =
    OnboardingQueryParams.FORTE_PROMO_ID in queries
      ? (queries[OnboardingQueryParams.FORTE_PROMO_ID] as KnownFortePromoIds)
      : null;

  const createLearnerOfFromMatchingRequestId =
    OnboardingQueryParams.MATCHING_REQUEST_ID in queries
      ? (queries[OnboardingQueryParams.MATCHING_REQUEST_ID] as string)
      : null;

  const discountCodePresentAtSignUp =
    OnboardingQueryParams.DISCOUNT_CODE in queries
      ? (queries[OnboardingQueryParams.DISCOUNT_CODE] as string)
      : null;

  const signupCode =
    OnboardingQueryParams.SIGNUP_CODE in queries
      ? (queries[OnboardingQueryParams.SIGNUP_CODE] as string)
      : null;

  const email =
    (PersonalDetailsQueryParams.EMAIL in queries
      ? (queries[PersonalDetailsQueryParams.EMAIL] as string)
      : null) ||
    (OnboardingQueryParams.PARENT_EMAIL in queries
      ? (queries[OnboardingQueryParams.PARENT_EMAIL] as string)
      : null);
  const firstName =
    PersonalDetailsQueryParams.FIRST_NAME in queries
      ? (queries[PersonalDetailsQueryParams.FIRST_NAME] as string)
      : null;

  const correlationId =
    OnboardingQueryParams.CORRELATION_ID in queries
      ? (queries[OnboardingQueryParams.CORRELATION_ID] as string)
      : null;

  const onboardingTestId =
    OnboardingQueryParams.ONBOARDING_TEST_ID in queries
      ? (queries[OnboardingQueryParams.ONBOARDING_TEST_ID] as string)
      : null;

  // This is a fallback strictly for Tonestro. They launched without refid. This can be removed whenever they release afix to their app.
  const referringPartnerId =
    referringPartnerIdFromQueryParams ||
    (utmSource === "tonestro" ? "tonestro" : null);

  const providerValue = useMemo(() => {
    return {
      email,
      firstName,
      institutionFilter,
      onboardingMetadata: {
        correlationId,
        discountCodePresentAtSignUp,
        originMarketingPageSlug,
        onboardingTestId,
        referringPartnerId,
        userReferralCode,
        utmContent,
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        arbitraryUnhandledQueryParams,
        fortePromoId,
        createLearnerOfFromMatchingRequestId,
      },
      signupCode,
    };
  }, [
    arbitraryUnhandledQueryParams,
    correlationId,
    createLearnerOfFromMatchingRequestId,
    discountCodePresentAtSignUp,
    email,
    firstName,
    fortePromoId,
    institutionFilter,
    onboardingTestId,
    originMarketingPageSlug,
    referringPartnerId,
    signupCode,
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    utmTerm,
  ]);

  return (
    <OnboardingQueryParamCaptureContext.Provider value={providerValue}>
      {children}
    </OnboardingQueryParamCaptureContext.Provider>
  );
};
