import { JoinPageQueryParams } from "../constants/queryParams";

export const JOIN_MODE_KEY = "joinMode";

export type ExtraSignupParameters = {
  [JoinPageQueryParams.INSTRUMENT_ID]?: string;
  [JoinPageQueryParams.REDIRECT_URL]?: string;
};

export enum SignupModeOptions {
  LEARNER = "learner",
  PARENT = "parent",
  TEACHER = "teacher",
}

export type SignupModeT = SignupModeOptions | null;
